import React from 'react';
import {useSpring, animated} from 'react-spring';

// import logo from './logo.svg';
import './App.css';

function App() {
  const [flip, setFlip] = React.useState(false);
  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    reset: false,
    // reverse: flip,
    delay: 500,
    duration: 5000,
    // onRest: () => setFlip(!flip),
  });
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Hi.
        </p>
        <animated.div style={props}>
          I'm Michael.
          </animated.div>
        {/* <p>
        I'm a computer vision engineer.
        </p> */}
        <animated.div style={props}>
          I'm a computer vision engineer.
          </animated.div>
      </header>
    </div>
  );
}

export default App;
